import { Language } from 'components/hooks/language/useLanguage';
import { generatePath } from 'react-router-dom';

export const RoutePaths = {
    Home: {
        route: (lng: Language) => `/${lng}`,
        url: (lng: Language) => RoutePaths.Home.route(lng),
    },
    Services: {
        Default: {
            route: (lng: Language) => `/${lng}/services/:categorySeoPath?`,
            url: (lng: Language, categorySeoPath?: string) => generatePath(RoutePaths.Services.Default.route(lng), { categorySeoPath: categorySeoPath ?? null }),
        },
    },
    Solutions: {
        Default: {
            route: (lng: Language) => `/${lng}/solutions/:solutionSeoPath?`,
            url: (lng: Language, solutionSeoPath?: string) => generatePath(RoutePaths.Solutions.Default.route(lng), { solutionSeoPath: solutionSeoPath ?? null }),
        },
    },
    RequestAccess: {
        Default: {
            route: (lng: Language) => `/${lng}/request-access`,
        },
    },
    RequestDemo: {
        Default: {
            route: (lng: Language) => `/${lng}/request-demo`,
        },
    },
    Unsubscribe: {
        Default: {
            route: (lng: Language) => `/${lng}/subscription/unsubscribe/:code`,
            url: (lng: Language, code: string) => generatePath(RoutePaths.Unsubscribe.Default.route(lng), { code: encodeURIComponent(code) }),
        },
    },
    UnsubscribeValidated: {
        Default: {
            route: (lng: Language) => `/${lng}/subscription/unsubscribe-validated`,
            url: (lng: Language) => RoutePaths.UnsubscribeValidated.Default.route(lng),
        },
    },
    ServiceDetailModal: {
        SubPath: {
            subRoute: 'service/:serviceSeoPath',
            subUrl: (serviceSeoPath: string) => generatePath(RoutePaths.ServiceDetailModal.SubPath.subRoute, { serviceSeoPath }),
        },
    },
} as const;

export type RouteParams = {
    code?: string
    solutionSeoPath?: string,
    categorySeoPath?: string,
    serviceSeoPath?: string,
}