import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap';
import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';
import { SgwtProvider } from 'components/common/sgwtProvider/SgwtProvider';
import { I18nextProvider, LanguageRouteSync } from 'config/i18n';
import { DefaultOptions, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MatomoWidget } from 'components/common/matomo/MatomoWidget';
import { QumuConsentProvider } from 'components/contexts/qumuConsentContext';
import { ToastProvider } from 'components/common/toast/ToastProvider';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';
import { Metadata } from 'components/common/metadata';

import 'react-loading-skeleton/dist/skeleton.css';
import './css/bootstrapTheming.scss';
import './css/site.scss';

export const defaultQueryClientOptions: DefaultOptions = {
    queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: 60 * 60 * 1000, // remove data by GC if data unused/inactive more than 1 hour
    },
};

const queryClient = new QueryClient({
    defaultOptions: defaultQueryClientOptions,
});

const App: FC = () => (
    <QueryClientProvider client={queryClient}>
        <HelmetProvider>
            <I18nextProvider>
                <SgwtProvider>
                    <BrowserRouter>
                        <ErrorBoundary>
                            <Metadata />
                            <LanguageRouteSync />
                            <MatomoWidget />
                            <QumuConsentProvider>
                                <AppRoutes />
                                <ToastProvider />
                            </QumuConsentProvider>
                        </ErrorBoundary>
                    </BrowserRouter>
                </SgwtProvider>
            </I18nextProvider>
        </HelmetProvider>
    </QueryClientProvider>
);

const start = () => {
    const container = document.getElementById('root');

    const root = createRoot(container!);
    root.render(<App />);
    const appInitLoader = document.getElementById('app-init-loader');
    if (appInitLoader) {
        appInitLoader.remove();
    }
};

start();
