import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { LoadingPage } from '../loading';
import { configuration } from 'config/constants';

const loadScript = (url: string) => new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = url;
    scriptElement.async = true;
    scriptElement.onload = resolve;
    scriptElement.onerror = reject;

    document.head.appendChild(scriptElement);
});

export const SgwtProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-widget-polyfills/sgwt-widget-polyfill-webcomponent.js`)
            .then(() => {
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-web-analytics/v4/sgwt-web-analytics.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-help-center/v4/sgwt-help-center.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-splash-screen/v4/sgwt-splash-screen.js`);
            })
            .then(() => setIsLoading(false));
    }, []);

    return <>
        {isLoading ? <LoadingPage /> : children}
    </>;
};
