import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const Metadata = () => {
    const { t: translate } = useTranslation('metadata');
    return (
        <Helmet>
            <title>{translate('title')}</title>
            <meta name="description" content={translate('description')} />
            <meta property="og:site_name" content="info.sgmarkets.com" />
            <meta property="og:image" content="/src/images/sg_logo.svg" />
            <meta property="og:url" content="https://info.sgmarkets.com" />
            <meta property="og:type" content="website" />
        </Helmet>
    );
};

