import React, {
  useState,
  useContext,
  createContext,
  FC,
  ReactNode,
  PropsWithChildren,
} from 'react';

interface IQumuConsentContext {
  consent: boolean;
  setConsent: (consent: boolean) => void;
}

export const QumuConsentContext = createContext<IQumuConsentContext>({
  consent: false,
  setConsent: () => {},
});

export const useQumuConsent = () => useContext(QumuConsentContext);

interface QumuConsentProvider {
  children: ReactNode;
}
export const QumuConsentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [consent, setConsent] = useState<boolean>(false);

  return (
    <QumuConsentContext.Provider
      value={{
        consent,
        setConsent,
      }}
    >
      {children}
    </QumuConsentContext.Provider>
  );
};
