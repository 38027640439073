import React, { FC, PropsWithChildren, useEffect } from 'react';
import i18n, { changeLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from './backend';
import { messages, locales } from 'virtual-vite-i18n-resources';
import { I18nextProvider as Provider } from 'react-i18next';
import { DEFAULT_LANGUAGE, AvailableLanguages, useLanguage } from 'components/hooks/language/useLanguage';
import LanguageDetector from 'i18next-browser-languagedetector';

if (!i18n.isInitialized) {
    i18n
        .use(backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            backend: {
                loadPath: (lang: string) => locales?.[lang] ? `/${locales[lang]}` : undefined,
            },
            fallbackLng: DEFAULT_LANGUAGE,
            supportedLngs: AvailableLanguages,
            interpolation: {
                escapeValue: false,
            },
            react: {
                bindI18nStore: 'added',
            },
            load: 'languageOnly',
            detection: {
                order: ['path', 'localStorage', 'navigator'],
                convertDetectedLanguage: (lng: string) => lng.split('-')[0],
            },
        });
}

if (messages) {
    for (const lang in messages) {
        for (const ns in messages[lang]) {
            i18n.addResourceBundle(lang, ns, messages[lang][ns], true, true);
        };
    };
}

export const I18nextProvider: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Provider i18n={i18n}>
            {children}
        </Provider>
    );
};

export const LanguageRouteSync: FC = () => {
    const { i18nLanguage, urlLanguage } = useLanguage();

    useEffect(() => {
        // Prior to language in URL
        // Usefful when user use navigation button and the language in the url changed
        // Or when user change language from dropdown
        if (urlLanguage && urlLanguage !== i18nLanguage) {
            changeLanguage(urlLanguage);
        }
    }, [i18nLanguage, urlLanguage]);

    return false;
};
