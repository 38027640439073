import React, { FC } from 'react';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const ToastProvider: FC = () =>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeButton={false}
        icon={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName={context => classNames(
            'toast fade show',
            {
                'toast-danger': context?.type === 'error',
                'toast-info': context?.type === 'info',
                'toast-success': context?.type === 'success',
                'toast-warning': context?.type === 'warning',
                'toast-primary': context?.type === 'default',
            },
        )}
        bodyClassName={() => ''}
        progressClassName={context => classNames(
            context?.defaultClassName,
            {
                'bg-danger': context?.type === 'error',
                'bg-info': context?.type === 'info',
                'bg-success': context?.type === 'success',
                'bg-warning': context?.type === 'warning',
                'bg-primary': context?.type === 'default',
            },
        )}
        progressStyle={{ height: '3px' }}
    />;