import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useMatch } from 'react-router-dom';

type UseLanguageResponse = {
    selectedLanguage: Language;
    i18nLanguage: Language;
    urlLanguage: Language | undefined;
    changeSelectedLanguage: (language: Language) => void;
};

export type Language = 'en' | 'fr';
export const DEFAULT_LANGUAGE = 'en';
export const AvailableLanguages = [DEFAULT_LANGUAGE, 'fr'] as const;

export const useLanguage = (): UseLanguageResponse => {
    const { i18n: { language } } = useTranslation();
    const { search, hash } = useLocation();
    const navigate = useNavigate();
    const m = useMatch('/:lng/*');

    const urlLanguage = AvailableLanguages.find(x => x === m?.params?.lng?.toLocaleLowerCase());
    const i18nLanguage = language as Language;

    const changeSelectedLanguage = (lang: Language) => {
        if (i18nLanguage !== lang) {
            const otherPath = m?.params['*'] ? `/${m?.params['*']}` : '';
            navigate(`/${lang}${otherPath}${search}${hash}`);
        }
    };

    return {
        selectedLanguage: urlLanguage ?? i18nLanguage,
        i18nLanguage: i18nLanguage,
        urlLanguage: urlLanguage,
        changeSelectedLanguage,
    };
};
